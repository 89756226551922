import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';

const ContentContainer = styled(Box)(({ theme }) => ({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '3rem',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    padding: '3rem 3rem 3rem 6rem',
  },
}));

const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 800px;
  margin: 0 auto;
`;

const Instruction: React.FC = () => {
  return (
    <ContentContainer>
      <TextContainer>
        <Typography variant="h6" gutterBottom>
          Start searching for plans now
          <br />
        </Typography>
        <Typography variant="body1" gutterBottom>
          Login with your Powersensor account credentials. Or, upload your energy usage in a NEM12 or NEM13 format.
        </Typography>
        <br />
        <Typography variant="h6" gutterBottom>
          How to obtain your energy usage data in NEM12 or NEM13 format?
          <br />
        </Typography>
        <Typography variant="body1" gutterBottom>
          To access your energy usage data in NEM12 or NEM13 format, typically a .csv file, you can request this from your Energy
          Retailer or Distributor. By acquiring your energy usage data in NEM12 or NEM13 format, you can gain deeper insights into
          your energy consumption patterns and potentially identify areas for energy efficiency improvements.
        </Typography>
      </TextContainer>
      <br />
    </ContentContainer>
  );
};

export default Instruction;
