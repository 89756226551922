import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import bannerImage from 'assets/png/banner.png';

const BannerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F4F7F9',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'left',
  padding: '3rem',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    padding: '3rem 3rem 3rem 6rem',
  },
}));

const ImageContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const Banner: React.FC = () => {
  return (
    <BannerContainer>
      <ContentContainer>
        <Typography variant="h5" gutterBottom>
          It pays to compare tariffs, whether you’re a Powersensor customer or not—we have the tariff compare tool for you.
        </Typography>
        <br />
        <Typography variant="body1" gutterBottom>
          Anyone who has experienced bill shock will know it pays to compare your electricity rates and plans and with more demand
          tariffs entering the market, it pays to compare.
        </Typography>
        <br />
        <Typography variant="h6" gutterBottom>
          How it works
          <br />
        </Typography>
        <Typography variant="body1" gutterBottom>
          Simply upload your bill data in NEM12 or NEM13 file formats, or see it automated by signing in with your Powersensor
          account to start saving. The Powersensor Tariff Tool will show you a breakdown of your current energy usage and cost.
        </Typography>
        <br />
        <Typography variant="h6" gutterBottom>
          Best plans by postcode
          <br />
        </Typography>
        <Typography variant="body1" gutterBottom>
          You can search for plans by postcode and see all of the retailers in your area, to find the most cost-effective energy
          plan for your needs.
        </Typography>
        <br />
        <Typography variant="body1" gutterBottom>
          Discover the ideal energy plan for your household, in just a few simple steps!
          <br /> --
        </Typography>

        <Typography variant="caption" gutterBottom>
          <p>
            Disclaimer: Powersensor is not affiliated with any energy retailer, as such, receives no commission, and offers this as
            a service to its users. Powersensor collects data directly from the AER.
          </p>
          <p>
            So we must advise, the AER does not verify the accuracy, currency or completeness of the information provided by energy
            companies on this website. Accordingly, the AER does not guarantee or warrant the accuracy, currency or completeness of
            the information provided.
          </p>
          <p>Please always consult the retailer to ensure tariffs are current and accurate.</p>
          <p>
            Powersensor user accounts, do not currently work with secondary loads / tariffs. In the future we will endeavour to
            support this tariff type
          </p>
        </Typography>
      </ContentContainer>
      <ImageContainer>
        <StyledImage src={bannerImage} alt="Banner" />
      </ImageContainer>
    </BannerContainer>
  );
};

export default Banner;
